import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";
import Advertisement from "@/components/Advertisement.vue";
import {
    apiNewList,
    apibannerLong
} from "../../../../request/api.js";
export default {
    components: {
        Header,
        Footer,
        Advertisement
    }, //添加注册组件事件
    data() {
        return {
            list: [],
            bannerSubtitle: '',
            bannerTitle: '',
            bannerLong: '',
            nodata: false,
        };
    },
    created() {
        this.newList()
        this.getbannerLong()
    },
    mounted() {},
    methods: {
        // 获取新闻列表
        newList() {
            apiNewList({}).then(res => {
                this.list = res.data.data
                if (res.data.data.length == 0) {
                    this.nodata = true
                }
            });
        },
        // 获取新闻列表

        //  跳转更多列表
        LinkNewListChildren(plateId, title) {
            this.$router.push({
                name: "newListChildren",
                query: {
                    plateId: escape(plateId),
                    title: escape(title)
                }
            });
        },
        //  跳转更多列表

        LinkDetails(id, title, plateId) {
            window.location.href = this.FunPublic.url + "newDetails.html?" + "id=" + escape(id) + "&title=" + escape(title) + "&plateId=" + escape(plateId) + "&typemodel=0"
        },

        //获取长租页面的图片以及文字接口数据
        getbannerLong() {
            apibannerLong({
                menu: "plate_fxtx"
            }).then(res => {
                if (res.data.code == 200) {
                    this.bannerSubtitle = res.data.data.subtitle
                    this.bannerTitle = res.data.data.title
                    this.bannerLong = res.data.data.image
                }
            });
        }
    },
};